import React from "react";
import styled from "styled-components";
import { ReactComponent as group } from "../../assets/Svgs/group.svg";
import { ReactComponent as UserProfile } from "../../assets/Svgs/userProfile.svg";
interface IProps {
  text1: string;
  text2: string;
  OnClick?: Function;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const CardFile = ({ text1, text2, Icon, OnClick }: IProps) => {
  return (
    <>
      <Body>
        <DivIcone
          onClick={() => {
            OnClick && OnClick();
          }}
        >
          <Icon />
        </DivIcone>
        <DivText>
          <div id="title1">{text1}</div>
          <div id="title2">{text2}</div>
        </DivText>
      </Body>
    </>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25vw;
`;
const DivIcone = styled.div`
  height: 4rem;
  width: 4rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20%;
  background: white;
  svg {
    width: 60%;
    height: 60%;
    opacity: 1;
    z-index: 2;
    fill: #3c85dc;
  }
`;

const DivText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  #title1 {
    margin-bottom: -0.3rem;
  }
`;
