import React, { Dispatch } from "react";
import styled, { keyframes } from "styled-components";
import { File } from "../File";
import { Folder } from "../Folder";

export interface TreeNodeType {
  _id: string;
  name: string;
  children?: string[];
  parent: string | null;
  displayName: string;
}

interface Props {
  node: TreeNodeType;
  isSecondLevel: boolean;
  setRefresh?: any;
  firstFile?: boolean;
  refresh: boolean;
  refreshParent?: Function;
  setFile: Dispatch<React.SetStateAction<string>>;
  setSelectedFolder?: Dispatch<
    React.SetStateAction<
      // TreeNodeType
      (TreeNodeType & { onClickFunction: Function }) | undefined
    >
  >;
}

export const TreeNode: React.FC<Props> = ({
  node,
  isSecondLevel,
  setRefresh,
  firstFile,
  refresh,
  refreshParent,
  setSelectedFolder,
  setFile,
}) => {
  return (
    <Node>
      {"children" in node ? (
        <Folder
          refresh={refresh}
          setFile={setFile}
          setRefresh={setRefresh}
          isSecondLevel={isSecondLevel}
          node={node}
          refreshParent={refreshParent}
          setSelectedFolder={setSelectedFolder}
        />
      ) : (
        <File
          refresh={refresh}
          setRefresh={setRefresh}
          firstFile={firstFile ? true : false}
          setFile={setFile}
          node={node}
          refreshParent={refreshParent}
        />
      )}
    </Node>
  );
};

const Node = styled.div`
  display: flex;
  flex-direction: column;
`;

const spin = keyframes`
  0% {
     transform: rotate(0deg);
     }
  100% {
     transform: rotate(360deg);
      }
`;

export const Spinner = styled.div`
  position: relative;
  bottom: 2.4rem;
  right: 1.25rem;
  border: 0.3rem solid #f3f3f3; /* Light grey */
  border-top: 0.3rem solid #8fc8fa; /* Blue */
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  z-index: -1;
  animation: ${spin} 2s linear infinite;
`;
