// Burger.js
import React, { useEffect, useState } from "react";
import { Menu } from "./Menu";
import styled, { keyframes } from "styled-components";
import { ReactComponent as Arrow } from "../../assets/Svgs/arrow.svg";
import { hiddenPlus } from "../../store/userInfo";
import { useRecoilState } from "recoil";
interface IProps {
  open: Boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BurgerMenu = ({ open, setMenuOpen }: IProps) => {
  const [style, setStyle] = React.useState("");
  const [counter, setCounter] = useState(0);
  const [HiddePlus, setHiddePlus] = useRecoilState(hiddenPlus);

  useEffect(() => {
    if (counter) setStyle(open ? "slide-in-from-left" : "close");
    setCounter((value) => value + 1);
  }, [open]);

  return (
    <div style={{ overflow: "hidden" }}>
      <FullWrapper open={open} className={style}>
        <Menu open={open} />
        <CloseButton
          onClick={() => {
            setMenuOpen(false);
            setStyle("close");
            setHiddePlus(false);
          }}
        >
          <p>X</p>
          {/* <Arrow /> */}
        </CloseButton>
        <InvisblePart
          onClick={() => {
            setMenuOpen(false);
            setStyle("close");
          }}
        ></InvisblePart>
      </FullWrapper>
    </div>
  );
};

const CloseButton = styled.div`
  position: absolute;
  /* width: 3rem; */
  /* height: 3rem; */
  p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
  }
  top: 1vh;
  right: calc(35vw + 1rem);
  transform: scaleX(1.3);
  z-index: 6;
  opacity: 1;
`;

const InvisblePart = styled.div`
  width: unset;
  height: 100vh;
`;
interface IPropsWrapper {
  open: Boolean;
}

const FullWrapper = styled.div<IPropsWrapper>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 20;
  opacity: 0.96;
  overflow: hidden;
  visibility: hidden;
`;
