import axios from "axios";
import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { BASE_URL } from "../../api/constants";
import { ReactComponent as Preview } from "../../assets/Svgs/Preview.svg";
import { ReactComponent as Download } from "../../assets/Svgs/Download.svg";
import { ReactComponent as Share } from "../../assets/Svgs/Share.svg";

interface IProps {
  text: string;
  number: number;
  fileId: string;
  startDate: Date;
  endDate: Date;
  colorOne?: string;
  colorTwo?: string;
}

export const PopularFile = ({
  startDate,
  endDate,
  text,
  number,
  fileId,
  colorOne,
  colorTwo,
}: IProps) => {
  const [actionCount, setActionCount] = useState<any>([]);
  const [showActionCount, setShowActionCount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getFileReport = () => {
    setIsLoading(true);
    axios
      .post(
        `${BASE_URL}/fileLogs/getFileReport`,
        { fileId, startDate, endDate },
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        setIsLoading(false);
        if (result.data.success) {
          setActionCount(result.data.actionCount);
        }
      });
  };

  return (
    <Container>
      <GreenSquare
        colorOne={colorOne}
        colorTwo={colorTwo}
        onClick={() => {
          getFileReport();
          setShowActionCount((prev) => !prev);
        }}
      >
        <div id="number">{number}</div>
      </GreenSquare>
      {showActionCount ? (
        isLoading ? (
          <TextWrapper>
            <div id="text">{"טוען..."}</div>
          </TextWrapper>
        ) : (
          <TextWrapper>
            <ActionCountContainer>
              {actionCount.map((action: any) => {
                let icon;
                switch (action._id) {
                  case "Preview":
                    icon = <Preview />;
                    break;
                  case "Download":
                    icon = <Download />;
                    break;
                  case "Share":
                    icon = <Share />;
                    break;
                }
                return (
                  <ActionCount>
                    <div id="number">{action.count}</div>
                    {icon}
                  </ActionCount>
                );
              })}
            </ActionCountContainer>
          </TextWrapper>
        )
      ) : (
        <TextWrapper>
          <div id="text">{text}</div>
        </TextWrapper>
      )}
    </Container>
  );
};
const TextWrapper = styled.div`
  border: 2px solid #f6f8f7;
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05),
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  width: 80%;
  padding: 0.5rem;
  margin: 0;
  margin: auto;
  border-radius: 1rem;
  height: 5vh;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  #text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    margin-right: 2.5rem;
    max-width: 80%;
    font-size: 1.2rem;
  }
`;

const ActionCount = styled.div`
  transform: scale(0.8);
  background-color: #9921e8;
  background-image: linear-gradient(315deg, #9921e8 0%, #5f72be 74%);
  border-radius: 50%;
  width: 3.1rem;
  height: 3.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  #number {
    color: white;
    font-weight: 500;
  }
  svg {
    fill: #e2e3e5;
    width: 1rem;
    height: 1rem;
  }
`;

const ActionCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

interface GreenSquareProps {
  colorOne?: string;
  colorTwo?: string;
}

const GreenSquare = styled.div<GreenSquareProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 1.5rem;
  border-radius: 0.5rem 0.5rem 0.5rem 0;
  background: ${props=> props.colorOne? `linear-gradient(45deg, ${props.colorOne} 20%, ${props.colorTwo} 130%);` : "linear-gradient(45deg, #a2ed56 20%, #fddc32 130%)"};
  /* background: linear-gradient(45deg, #a2ed56 20%, #fddc32 130%); */
  padding: 1.5rem;
  margin-bottom: 2.7rem;
  #number {
    font-size: 1.3rem;
    font-weight: 900;
    position: absolute;
    margin-top: 0.8rem;
    margin-right: 0.5rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-top: 2.5rem;
  margin-right: -1rem;

  /* justify-content: flex-start; */
`;
