import React from "react";
import styled from "styled-components";

interface IProps {
  placeholder: string;
  value: string;
  topText?: string;
  bottomText?: string;
  isTextArea: boolean;
  isDisabled?: boolean;
  setValue: React.Dispatch<string>;
}

const InputStyle = styled.div`
  background-color: #dce7f1;
  border-radius: 2rem;
  width: 80%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  textarea {
    height: 5rem;
    resize: none;
  }
  textarea,
  input {
    text-align: right;
    font-family: "Assistant", sans-serif;
    unicode-bidi: plaintext;
    align-self: center;
    justify-self: center;
    border: none;
    border-radius: 1rem;
    width: 80%;
    padding: 1rem 0;
    font-weight: 500;
    font-size: 0.9rem;
    padding-right: 1rem;
    ::placeholder {
      font-weight: 500;
      padding-right: 0.2rem;
    }
    :focus {
      outline: none;
    }
    :disabled {
      background-color: #f1f1f1;
    }
  }
  p {
    font-size: 14px;
    font-weight: 600;
  }
  p,
  h4 {
    color: #2e3841;
    margin: 0.75rem 0;
    margin-right: 12%;
    font-weight: 600;
    /* padding: 0; */
  }
`;

export const Input = ({
  placeholder,
  value,
  setValue,
  topText,
  bottomText,
  isTextArea,
  isDisabled,
}: IProps) => {
  return (
    <InputStyle>
      <h4>{topText}</h4>
      {isTextArea ? (
        <textarea
          placeholder={placeholder}
          disabled={isDisabled}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      ) : (
        <input
          type="text"
          disabled={isDisabled}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
      <p>{bottomText}</p>
    </InputStyle>
  );
};
