import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Card } from "../../atoms/Card";
import { ACADEMY_COURSES_URL, CARDS, SMART_BASE_URL } from "../../constants";
import { DefaultContainer } from "../../containers/DefaultContainer";
import usePermissions from "../../hooks/usePermissions";
import { ButtonWithIcon } from "../ButtonWithIcon";
import { Notification } from "../Notification";
import { PersonalFiles } from "./PersonalFiles";
import axios from "axios";
import { BASE_URL } from "../../api/constants";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import { Updates } from "./Updates";

export const Home = () => {
  const history = useHistory();
  const { isAdminUser } = usePermissions("home");
  const user = useRecoilValue(userInfo);
  const email = user.user.userPrincipalName;

  const isNewUser = useCallback(() => {
    setTimeout(() => {
      axios.get(`${BASE_URL}/auth/isNewUser`, {
        withCredentials: true,
      });
      axios.post(
        `${BASE_URL}/userLogs/sendUserLog`,
        { email },
        {
          withCredentials: true,
        }
      );
    }, 1000);
  }, []);

  useEffect(() => {
    isNewUser();
  }, [isNewUser]);

  return (
    <DefaultContainer background={true} search={true}>
      <HomeWrapper>
        <CardsWrapper>
          <CardsColWrapper>
            <Card
              isActive={true}
              text={CARDS[0]}
              color="#0095CD"
              bgColor="#EDF7FE"
              onClickFunc={() => {
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[0] },
                  {
                    withCredentials: true,
                  }
                );
                history.push("/frameworkOrdersLibrary");
              }}
            />

            <Card
              isActive={true}
              text={CARDS[1]}
              color="#FF6DE5"
              bgColor="#FEEDFB"
              onClickFunc={() => {
                history.push("/womenFighters");
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[1] },
                  {
                    withCredentials: true,
                  }
                );
              }}
            />

            <Card
              isActive={true}
              text={CARDS[2]}
              color="#23CD52"
              bgColor="#E7FCEA"
              textSize={14}
              onClickFunc={() => {
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[2] },
                  {
                    withCredentials: true,
                  }
                );
                history.push("/armouredFightingVehicleLibrary");
              }}
            />
            <Card
              isActive={true}
              text={CARDS[6]}
              color="#f63434"
              bgColor="#FEEDFB"
              onClickFunc={() => {
                history.push("/marom");
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[6] },
                  {
                    withCredentials: true,
                  }
                );
              }}
            />
          </CardsColWrapper>
          <CardsColWrapper>
            <Card
              isActive={true}
              text={CARDS[3]}
              color="#936EFF"
              bgColor="#F1EDFE"
              onClickFunc={() => {
                history.push("/competencies");
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[3] },
                  {
                    withCredentials: true,
                  }
                );
              }}
            />
            <Card
              isActive={true}
              text={CARDS[4]}
              color="#FFA368"
              bgColor="#FBF0E9"
              onClickFunc={() => {
                history.push("/techniques");
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[4] },
                  {
                    withCredentials: true,
                  }
                );
              }}
            />

            <Card
              isActive={true}
              text={CARDS[5]}
              color="#4A74FF"
              bgColor="#EDF1FE"
              onClickFunc={() => {
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[5] },
                  {
                    withCredentials: true,
                  }
                );
                history.push("/ammoLibrary");
              }}
            />

            <Card
              isActive={true}
              text={CARDS[7]}
              color="#c70909"
              bgColor="#FEEDFB"
              onClickFunc={() => {
                history.push("/ironSwords");
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[7] },
                  {
                    withCredentials: true,
                  }
                );
              }}
            />
            {/* <Card
              isActive={true}
              text={CARDS[7]}
              color="#cdd762"
              bgColor="#EDF1FE"
              onClickFunc={() => {
                window.location.replace(
                  "https://campus.digital.idf.il/course/view.php?id=3093"
                );
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[7] },
                  {
                    withCredentials: true,
                  }
                );
              }}
            /> */}
          </CardsColWrapper>
        </CardsWrapper>
        <ButtonsWrapper>
          {/* <ButtonWithIcon
            text={"טפסים"}
            onClickFunc={() => {
              history.push("/documents");
            }}
          />
          <ButtonWithIcon
            text={"שאלות ותשובות"}
            onClickFunc={() => {
              history.push("/Q&A");
            }}
          /> */}
        </ButtonsWrapper>
        <Updates />
      </HomeWrapper>
    </DefaultContainer>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HomeWrapper = styled.div`
  /* min-width: 100vw;
  min-height: 100%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.1rem;
`;

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CardsColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
