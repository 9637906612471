import { useState } from "react";
import styled from "styled-components";
import { BASE_URL } from "../../api/constants";
import bg from "../../assets/login/bg.png";
import { ContactButton } from "./ContactButton";
import { ContactInput } from "./ContactInput";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import axios from "axios";
import { Dispatch } from "react";
interface IProps {
  setNewTicket: Dispatch<React.SetStateAction<boolean>>;
}
const sendTicket = (email: String, content: String, type: String) => {
  return axios
    .post(
      `${BASE_URL}/contactTickets/sendTicket`,
      {
        email,
        content,
        type,
      },
      { withCredentials: true }
    )
    .then((res) => {
      if (res?.data?.success) {
        return true;
      }
      return false;
    });
};

export const NewTicket = ({ setNewTicket }: IProps) => {
  const user = useRecoilValue(userInfo);
  const [content, setContent] = useState("");
  const [message, setMessage] = useState("");
  const email = user.user.userPrincipalName;

  return (
    <Wrapper>
      <br />
      <FormWrapper>
        <Header>
          <h3>{`צור קשר`}</h3>
          <p>
            {`כדי לעזור לנו לשפר את K.click, ניתן ליצור איתנו קשר לגבי בעיות שאתם נתקלים בהן.`}
          </p>
        </Header>
        <br />

        <ContactInput
          placeholder=""
          value={user.user.displayName}
          isDisabled={true}
          setValue={setContent}
          isTextArea={false}
          topText={"שם"}
          bottomText={""}
        ></ContactInput>

        <ContactInput
          placeholder=""
          value={content}
          setValue={setContent}
          isTextArea={true}
          topText={"תוכן הפנייה"}
          bottomText={""}
        ></ContactInput>
        <Buttons>
          <ContactButton
            onClick={async () => {
              const sent = await sendTicket(email, content, "פנייה לעזרה");
              if (sent) {
                setMessage("הפנייה נשלחה בהצלחה.");
              } else {
                setMessage("קרתה תקלה בעת שליחת הפנייה");
              }
            }}
          >
            {"שלח"}
          </ContactButton>
          <ContactButton
            onClick={() => setNewTicket(false)}
          >{`חזור`}</ContactButton>
        </Buttons>
        {message && <Message>{message}</Message>}
      </FormWrapper>
    </Wrapper>
  );
};

const Message = styled.div`
  font-weight: 600;
  margin-bottom: 1rem;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const FormWrapper = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: white;
  border-radius: 1rem;
  width: 90vw;
  margin-right: 5vw;
  max-height: 95vh;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    padding-top: 1rem;
  }
  p {
    align-self: center;
    text-align: center;
    /* font-size: 1.2rem; */
    font-weight: 600;
    width: 80%;
  }
`;
const Wrapper = styled.div`
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
`;
