import React, { useEffect, useState } from "react";
import styled from "styled-components";
import favoritesIcon from "../../../assets/homepage/Fav.png";
import editIcon from "../../../assets/homepage/edit.png";
import axios from "axios";
import { Center } from "./Center";
import { File } from "../../../atoms/FileRow";
import { IUpdate } from "../../AdminsUpdates/Update";
import { BASE_URL } from "../../../api/constants";

// const demoFile: File = {
//   name: "טבלאות אקסל שלי",
//   trace: "ספרות הבטיחות / מנחים",
//   type: "Excel",
// };

export const Updates = () => {
  const [updates, setUpdates] = useState<IUpdate[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${BASE_URL}/adminUpdates/getUserUpdates`, {
        withCredentials: true,
      })
      .then((res) => {
        res?.data?.success ? setUpdates(res.data.updates) : setUpdates([]);
        setIsLoading(false);
      });
  }, []);
  return (
    <UpdatesWrapper>
      <Top>
        <img
          style={{ alignSelf: "center", width: "1rem", height: "1rem" }}
          src={favoritesIcon}
          alt="מועדפים"
        />
        {/* <p>{`אזור אישי (${personalFiles.length})`}</p> */}
        {`הודעות ועדכונים ${isLoading ? "" : `(${updates.length})`}`}
      </Top>
      <Center isLoading={isLoading} updates={updates} />
      <Bottom />
    </UpdatesWrapper>
  );
};

const Top = styled.div`
  width: 100%;
  font-weight: 600;
  display: flex;
  background-color: #8fc8fa;
  border: 1px solid #8fc8fa;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  img {
    margin: 0 0.5rem;
  }
  /* @media (max-width: 768px) {
    width: 80vw;
  } */
`;

const Bottom = styled.div`
  width: 100%;
  height: 1rem;
  display: flex;
  background-color: white;
  border: 1px solid white;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  /* @media (max-width: 768px) {
    width: 80vw;
  } */
`;

const UpdatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  max-width: 90vw;
  min-width: 90vw;
  max-height: 30vh;
  margin: 1rem 0.5rem;
  /* margin-bottom: 1rem; */
  position: relative;
  &.notactive {
    display: flex;
    justify-content: center;
    padding: 0 0.1rem;
    &::before {
      content: "";
      background-color: gray;
      opacity: 0.4;
      z-index: 2;
      border-radius: 1rem;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
    &::after {
      position: absolute;
      align-self: center;
      text-align: center;
      content: "בקרוב..";
      font-size: 1.5rem;
      font-weight: 600;
      transform: rotate(340deg);
      color: black;
      z-index: 3;
    }
  }
`;
