import React from "react";
import styled, { keyframes } from "styled-components";
import madorLogo from "../../assets/madorLogo.png";
import { LoadingCircle } from "../LoadingCircle";
export const LoadingPage = () => {
  return (
    <>
      <Text>פותח ע"י</Text>
      <ImgLogo src={madorLogo}></ImgLogo>
      <LoadingCircle />
    </>
  );
};

const Text = styled.div`
  position: absolute;
  font-size: 1.2rem;
  color: #afafaf;
  left: 43%;
  top: 83%;
  text-shadow: 2px 2px 5px #888888;
`;

const ImgLogo = styled.img`
  transform: scale(0.6);
  position: absolute;
  height: 194px;
  width: 252px;
  object-fit: cover;
  left: 25%;
  top: 80%;
`;
