import { Dispatch } from "react";
import styled from "styled-components";
import bg from "../../assets/login/bg.png";
import { ITicket } from ".";

interface IProps {
  ticket: ITicket;
  setViewTicket: Dispatch<React.SetStateAction<boolean>>;
  setTicket: Dispatch<React.SetStateAction<ITicket>>;
  isSupportUser: boolean;
}

export const Ticket: React.FC<IProps> = ({
  ticket,
  setViewTicket,
  setTicket,
  isSupportUser,
}: IProps) => {
  let { fullName: name, createdAt: date, status } = ticket;
  date = new Date(date);
  const open: string = "open";
  const closed: string = "closed";
  const statusDisplay = {
    [open]: `בטיפול`,
    [closed]: `נסגר`,
  };

  return (
    <>
      <BackgroundDiv
        onClick={() => {
          if (ticket?.reply || isSupportUser) {
            setTicket(ticket);
            setViewTicket(true);
          }
        }}
      >
        <Line open={ticket?.reply ? true : false} />
        <CardContent>
          <div id="topCard">
            <div>{name}</div>
            <DateDiv>
              <div>{date.getDate()}</div>
              <div>{date.getMonth() + 1}.</div>
              <div>{date.getFullYear().toString().substr(-2)}.</div>
            </DateDiv>
          </div>
          <div id="status">{statusDisplay[`${status}`]}</div>
        </CardContent>
      </BackgroundDiv>
    </>
  );
};
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 93%;
  #topCard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 0.2rem;
    font-weight: 400;
    font-size: 1rem;
  }
  #status {
    font-weight: 500;
    font-size: 1.2rem;
  }
`;
interface LineProps {
  open: boolean;
}
const Line = styled.div<LineProps>`
  height: 100%;
  min-width: 5px;
  background-color: ${(props) => (props.open ? "#46a32fc7" : "#ff6663")};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: 0.5rem;
`;
const DateDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const BackgroundDiv = styled.div`
  background-color: #f2f2f2;
  width: 95%;
  border-radius: 5px;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  height: 4rem;
  box-shadow: 0px 2px 4px rgba(93, 98, 109, 0.4);
`;
