import styled from "styled-components";
import { MdPrint, MdShare, MdStar, MdVisibility } from "react-icons/md";
import axios from "axios";
import { BASE_URL } from "../../api/constants";
import { FileShare } from "../FileActions/Share";
import { TreeNodeType } from "../TreeNode";
import { Dispatch, useEffect, useState } from "react";
import { FilePreview } from "../FileActions/Preview";
import { FileDelete } from "../FileActions/Delete";
import usePermissions from "../../hooks/usePermissions";
import { userInfo } from "../../store/userInfo";
import { useRecoilValue } from "recoil";
import { FileDownload } from "../FileActions/Download";
interface IProps {
  // node: { name: string; _id: string };
  node: TreeNodeType;
  setFile: Dispatch<React.SetStateAction<string>>;
}
export const OptionsBar: React.FC<IProps> = ({ node, setFile }: IProps) => {
  const [text, setText] = useState("");
  const [shortName, setShortName] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [fileType, setFileType] = useState(node.name.split(".").slice(-1)[0]);

  const { isContentManagerUser, isAdminUser } = usePermissions("search");

  const user = useRecoilValue(userInfo);
  const email = user.user.userPrincipalName;

  useEffect(() => {
    const maxChars = 25;
    let trimmedName = node.displayName.split(".").slice(0, -1).join(".");
    if (trimmedName) {
      if (trimmedName.length > maxChars) {
        trimmedName = trimmedName.substring(0, maxChars - 1) + "..";
      }
      setShortName(trimmedName);
      setText(trimmedName);
    }
  }, []);

  useEffect(() => {
    if (text != "קישור לקובץ הועתק בהצלחה." && text != "טוען...") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  }, [text]);

  return (
    <>
      {showPopup && <ResultPopup>{text}</ResultPopup>}
      <BarStyle scale={1.5}>
        {fileType == "pdf" && (
          <FilePreview
            setText={setText}
            setFile={setFile}
            filename={node.name}
            email={user.user.userPrincipalName}
            shortName={shortName}
          />
        )}

        <FileDownload
          setText={setText}
          filename={node.name}
          email={user.user.userPrincipalName}
          shortName={shortName}
        />

        {(isContentManagerUser || isAdminUser) && (
          <FileDelete setText={setText} node={node} shortName={shortName} />
        )}
        <FileShare
          setText={setText}
          filename={node.name}
          email={email}
          shortName={shortName}
        />
      </BarStyle>
    </>
  );
};

const ResultPopup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -8vh;
  margin-bottom: -6.3vh;
  height: 6vh;
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
`;

interface BarProps {
  scale: number;
}

const BarStyle = styled.div<BarProps>`
  width: 100%;
  background: #2e3841;
  display: flex;
  justify-content: space-around;
  position: relative;
  top: 1.05rem;
  /* margin-top: -0.1rem; */
  button {
    background: none;
    border: none;
    margin: 0.7rem auto;
  }
  img {
    margin-top: 0.4rem;
  }
  .star {
    width: calc(5 / 3 * 0.7rem);
    height: 0.7rem;
    transform: ${(props) => `scale(${props.scale})`};
  }
  .eye {
    width: calc(5 / 3 * 0.7rem);
    height: 0.7rem;
    transform: ${(props) => `scale(${props.scale})`};
  }

  .share {
    width: calc(5 / 3 * 0.7rem);
    height: 0.7rem;
    transform: ${(props) => `scale(${props.scale})`};
  }
`;
