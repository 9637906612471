import React from "react";
import { useRecoilValue } from "recoil";
import styled, { keyframes } from "styled-components";
import { useHistory } from "react-router";
import { userInfo } from "../../../../store/userInfo";
import axios from "axios";
import { BASE_URL } from "../../../../api/constants";
import { Modal } from "../../../../atoms/Modal";

interface IProps {
  fileId: string;
}

export const fileDownload = async (email: String, fileId: string) => {
  const { data } = await axios.get(`${BASE_URL}/tree/getFileName/${fileId}`, {
    withCredentials: true,
  });
  const filename = data.fileName;
  if (filename) {
    return axios
      .get(`${BASE_URL}/tree/downloadFile/${filename}`, {
        withCredentials: true,
      })
      .then((res) => {
        axios.post(
          `${BASE_URL}/fileLogs/sendFileLog`,
          { email, action: "Preview", fileName: filename },
          {
            withCredentials: true,
          }
        );
        return res.data;
      })
      .catch((err) => {
        console.log(err);

        return false;
      });
  } else {
    return false;
  }
};

const getTrace = async (email: String, fileId: string) => {
  const { data } = await axios.get(`${BASE_URL}/tree/getFileName/${fileId}`, {
    withCredentials: true,
  });
  const filename = data.fileName;
  if (filename) {
    return axios
      .get(`${BASE_URL}/tree/getFileTrace/${filename}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data?.success) {
          return res.data.trace;
        } else {
          return undefined;
        }
      });
  } else {
    return undefined;
  }
};

export const UpdateSelect = ({ fileId }: IProps) => {
  const user = useRecoilValue(userInfo);
  const email = user.user.userPrincipalName;
  const [showModal, setShowModal] = React.useState(false);
  const [trace, setTrace] = React.useState("");

  return (
    <Wrapper>
      {email && (
        <Options>
          <div
            onClick={() => {
              fileDownload(email, fileId).then((url) => {
                if (url) {
                  window.open(url);
                }
              });
            }}
          >
            {"הורדה"}
          </div>
          <div
            onClick={() => {
              setShowModal(true);
              getTrace(email, fileId).then((trace) => {
                if (trace) {
                  let strTrace = trace[0];
                  for (let i = 1; i < trace.length; i++) {
                    strTrace += " > ";
                    strTrace += trace[i];
                  }
                  setTrace(strTrace);
                } else {
                  setTrace("הקובץ לא נמצא");
                }
              });
            }}
          >
            מקור
          </div>
        </Options>
      )}
      <Modal setShowModal={setShowModal} showModal={showModal}>
        <ModalWrapper>
          {trace ? (
            <>
              <div>{`ניתן למצוא את הקובץ בקטגוריית הידע הבטיחות תחת התיקייה הבאה:`}</div>
              <div>{`${trace}`}</div>
            </>
          ) : (
            <div>טוען..</div>
          )}
        </ModalWrapper>
      </Modal>
    </Wrapper>
  );
};

const dropdown = keyframes`
  from {
        max-height: 0px;
        opacity: 0;
      }
    
      to {
        max-height: min-content;
        opacity: 1;
      }
`;

const ModalWrapper = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  div {
    width: 80%;
    margin: 1rem 0;
  }
`;

const Wrapper = styled.div`
  width: min-content;
  height: min-content;
  /* margin-right: -6rem; */
  overflow: visible;
  z-index: 4;
  animation: ${dropdown} 0.5s ease-in-out;
`;

const Options = styled.div`
  position: absolute;
  display: flex;
  top: 1rem;
  left: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 6rem;
  div {
    display: flex;
    justify-content: center;
    font-weight: 600;
    padding: 1rem 0.5rem;
    background-color: white;
    width: 6rem;
    border: 1px solid gray;
  }

  a {
    color: black;
    font-weight: 600;
    text-decoration: none;
  }
`;
