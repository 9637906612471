import { atom } from "recoil";

export const searchWord = atom({
  key: "searchWord", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

export const newSearch = atom({
  key: "newSearch",
  default: false,
});

interface DataSearch {
  quat: [];
  results: [{ name: string; parent: string; text: string; _id: string }];
  roots: [[]];
  next: {};
}
export const dataSearch = atom({
  key: "dataSearch",
  default: {} as DataSearch,
});
