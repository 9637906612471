import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { DefaultContainer } from "../../../containers/DefaultContainer";
import { BASE_URL } from "../../../api/constants";
import { Modal } from "../../../atoms/Modal";
import usePermissions from "../../../hooks/usePermissions";
import { useHistory } from "react-router";
import { Input } from "../Input";
import { DateInput } from "../../DateInput";
import { Button } from "../../Button";

export const NewUpdate = () => {
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [output, setOutput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Wrapper>
      <FormWrapper>
        <h3>הודעה חדשה</h3>
        <Input
          placeholder=""
          value={category}
          setValue={setCategory}
          topText="קטגוריה"
          isTextArea={false}
        ></Input>
        <Input
          placeholder="כתוב הודעה"
          value={content}
          setValue={setContent}
          topText="תוכן ההודעה"
          isTextArea={true}
        ></Input>
        <Dates>
          <DateInput
            text={"תאריך התחלה"}
            value={startDate}
            setValue={setStartDate}
          />

          <DateInput
            text={"תאריך סיום"}
            value={endDate}
            setValue={setEndDate}
          />
        </Dates>
        <p>{output}</p>
        <Button
          disabled={isLoading}
          onClick={() => {
            setIsLoading(true);
            axios
              .post(
                `${BASE_URL}/adminUpdates/createUpdate`,
                {
                  content,
                  category,
                  startDate: new Date(startDate),
                  endDate: new Date(endDate),
                },
                { withCredentials: true }
              )
              .then((res) => {
                if (res?.data?.success) {
                  setOutput("ההודעה נוספה בהצלחה");
                } else {
                  setOutput(
                    "לא היה ניתן להוסיף את ההודעה, נסה שוב מאוחר יותר."
                  );
                  setIsLoading(false);
                }
              });
          }}
        >
          שלח
        </Button>
      </FormWrapper>
    </Wrapper>
  );
};

export default NewUpdate;

const Wrapper = styled.div`
  max-height: 80vh;
  width: 90vw;
  overflow: auto;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const Dates = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  justify-content: right;
  align-items: right;
`;
