import { atom } from "recoil";

interface FileProps {
  filename?: string;
  fileType?: string;
  fileSrc?: string;
}

export const fileInfo = atom<FileProps>({
  key: "fileInfo",
  default: { filename: undefined, fileType: undefined, fileSrc: undefined },
});
