import React from "react";
import styled from "styled-components";

const LodingStyle = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #ffefffe1;
  opacity: 0.1;
  z-index: 10000 !important;
  .circle {
    border-left: 1px solid red;
    border-bottom: 1px solid red;
    opacity: 1;

    border-radius: 50%;
    width: 30vw;
    height: 30vw;
  }
`;

export const Loading = () => {
  return (
    <LodingStyle>
      <div className={"circle"}></div>
    </LodingStyle>
  );
};
