import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IUpdate } from "../../AdminsUpdates/Update";

import More from "../../../assets/homepage/more.png";
import Update from "../../../assets/Svgs/update.svg";
import Speaker from "../../../assets/Svgs/Speaker.svg";
import MP4 from "../../../assets/Svgs/mp4.svg";
import PDF from "../../../assets/Svgs/pdf.svg";
import ADDFILE from "../../../assets/Svgs/add-files.svg";
import DELETEFILE from "../../../assets/Svgs/delete-files.svg";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../../store/userInfo";
import { UpdateSelect } from "./updateSelect";
const TypeIcon: React.FC<{ type: string }> = ({ type }) => {
  let iconSrc;
  switch (type) {
    case "מחיקת קובץ":
      iconSrc = DELETEFILE;
      break;
    case "יצירת קובץ":
      iconSrc = ADDFILE;
      break;
    case "PDF":
      iconSrc = PDF;
      break;
    case "MP4":
      iconSrc = MP4;
      break;
    default:
      iconSrc = Speaker;
      break;
  }
  return <Img src={iconSrc} alt="img" />;
};

interface IProps {
  content: string;
  category: string;
  date: string;
  file?: string;
}

export const UpdateRow: React.FC<IProps> = ({
  file,
  content,
  date,
  category,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const createDate = new Date(date);
  const [textContent, setTextContent] = useState("");
  const [clickContent, setClickContent] = useState(false);

  useEffect(() => {
    if (content.length > 50) {
      setTextContent(content.substring(0, 50) + "...");
    } else {
      setTextContent(content);
    }
  }, []);

  useEffect(() => {
    if (clickContent) {
      setTextContent(content);
    } else {
      if (content.length > 50) {
        setTextContent(content.substring(0, 50) + "...");
      }
    }
  }, [clickContent]);
  return (
    <Row>
      <RightSideWrapper>
        <TypeIcon type={category} />
        <NameTraceWrapper>
          <h5
            onClick={() => {
              setClickContent((prev) => !prev);
            }}
          >
            {textContent}
          </h5>
          <div className="grayWrapper">
            <p>{category}</p>
            <DateDiv>
              <div>{createDate.getDate()}</div>
              <div>{createDate.getMonth() + 1}.</div>
              <div>{createDate.getFullYear().toString().substr(-2)}.</div>
            </DateDiv>
          </div>
        </NameTraceWrapper>
      </RightSideWrapper>
      {isOpen && file && <UpdateSelect fileId={file} />}
      {file && category === "יצירת קובץ" && (
        <MoreImg
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
          src={More}
          alt="more"
        />
      )}
    </Row>
  );
};

const Img = styled.img`
  height: 4vh;
  width: 9vw;
  display: flex;
  margin: 0.7rem;
  margin-left: 1rem;
  /* @media (max-width: 768px) {
  } */
`;

const MoreImg = styled.img`
  height: 4vh;
  display: flex;
  margin: auto 0;
  padding: 0 0.5rem;
  @media (max-width: 768px) {
  }
`;

const Row = styled.div`
  width: 100%;
  /* height: 4rem; */
  /* max-height: 4rem; */
  /* height: fit-content; */
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 1px solid white;
  border-bottom: 1px solid #cdcdcd;
  padding: 0.3rem 0;
  /* @media (max-width: 768px) {
    width: 80vw;
  } */
`;

const NameTraceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: max-content;
  h5 {
    margin: 0;
    margin-bottom: 0.2rem;
    max-width: 65vw;
    min-width: 65vw;
    overflow: hidden;
  }
  p {
    margin: 0;
    margin-bottom: 0.2rem;
  }
  .grayWrapper {
    font-size: small;
    font-weight: 600;
    color: gray;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
  }
`;

const RightSideWrapper = styled.div`
  display: flex;
`;

const DateDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
