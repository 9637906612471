import location from "../assets/Svgs/location.svg";
import contentManager from "../assets/Svgs/contentManager.svg";
import safteySchedule from "../assets/Svgs/safteySchedule.svg";
import physioligical from "../assets/Svgs/physioligical.svg";
import academyIcon from "../assets/Svgs/academyIcon.svg";
import coneIcon from "../assets/Svgs/coneIcon.svg";
import ammoTin from "../assets/Svgs/ammoTin.svg";
import womanStretch from "../assets/Svgs/womanStretch.svg";
import tank from "../assets/Svgs/tank.svg";
import fitness from "../assets/Svgs/fitness.svg";
import marom from "../assets/Svgs/marom.svg";
import ironSwords from "../assets/Svgs/ironSwords.svg";
import campus from "../assets/campus.png";
import styled from "styled-components";
import { CARDS } from "../constants";

interface IProps {
  text: string;
  color: string;
  bgColor: string;
  isActive: boolean;
  onClickFunc?: () => void;
  textSize?: Number;
}

interface cardProps {
  color: string;
  isActive: boolean;
}

const logos: any = {
  [CARDS[0]]: contentManager,
  [CARDS[1]]: womanStretch,
  [CARDS[2]]: tank,
  [CARDS[3]]: fitness,
  [CARDS[4]]: safteySchedule,
  [CARDS[5]]: ammoTin,
  [CARDS[6]]: marom,
  [CARDS[7]]: ironSwords,
};

export const Card = ({
  text,
  color,
  bgColor,
  isActive,
  onClickFunc,
  textSize,
}: IProps) => {
  return (
    <CardWrapper
      onClick={() => {
        isActive && onClickFunc && onClickFunc();
      }}
      color={color}
      isActive={isActive}
      className={isActive ? "" : "active"}
    >
      <ImgWrapper color={bgColor} isActive={isActive}>
        {logos[text] && (
          <img
            style={{ alignSelf: "center", width: "1.7rem", height: "1.7rem" }}
            src={logos[text]}
            alt="logo"
          />
        )}
      </ImgWrapper>
      <p
        style={{
          margin: "0",
          whiteSpace: "nowrap",
          fontSize: `${textSize}px`,
          lineHeight: "1.5rem",
        }}
      >
        {text}
      </p>
    </CardWrapper>
  );
};

const CardWrapper = styled.div<cardProps>`
  background-color: white;
  padding: 0.5rem 2rem;
  margin: 0.5rem 0.5rem 0 0.5rem;
  /* width: 20vw; */
  /* height: 20vh; */
  border-radius: 1rem;
  color: ${(p: cardProps) => p.color};
  font-size: medium;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  &.active {
    &::before {
      content: "";
      background-color: gray;
      opacity: 0.4;
      z-index: 2;
      border-radius: 1rem;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
    &::after {
      position: absolute;
      align-self: center;
      content: "בקרוב..";
      font-size: 1.5rem;
      font-weight: 600;
      transform: rotate(340deg);
      color: black;
      z-index: 3;
    }
  }
`;

const ImgWrapper = styled.div<cardProps>`
  background-color: ${(p: cardProps) => p.color};
  border-radius: 2rem;
  padding: 0.8rem;
  /* width: 2vw;
  height: 2.5vh; */
  display: flex;
  justify-content: center;
  /* @media (max-width: 768px) {
    margin-top: 1rem;
    width: 8vw;
    height: 3vh;
  } */
`;
