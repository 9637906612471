import { useState } from "react";
import styled from "styled-components";
import { BASE_URL } from "../../api/constants";
import bg from "../../assets/login/bg.png";
import { ContactButton } from "./ContactButton";
import { ContactInput } from "./ContactInput";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import axios from "axios";
import { Dispatch } from "react";
import { ITicket } from ".";
import usePermissions from "../../hooks/usePermissions";
interface IProps {
  setViewTicket: Dispatch<React.SetStateAction<boolean>>;
  ticket: ITicket;
  isSupportUser: boolean;
}
const replyToTicket = (ticketId: String, reply: String) => {
  if (!reply) {
    return false;
  }
  return axios
    .post(
      `${BASE_URL}/contactTickets/reply`,
      {
        ticketId,
        reply,
      },
      { withCredentials: true }
    )
    .then((res) => {
      if (res?.data?.success) {
        return true;
      }
      return false;
    });
};

export const ViewTicket = ({
  setViewTicket,
  isSupportUser,
  ticket,
}: IProps) => {
  const user = useRecoilValue(userInfo);
  const [content, setContent] = useState("");
  const [message, setMessage] = useState("");
  const [reply, setReply] = useState(ticket?.reply ? ticket.reply : "");

  const email = user.user.userPrincipalName;

  return (
    <Wrapper>
      <br />
      <FormWrapper>
        <Header>
          {isSupportUser ? (
            ticket?.reply ? (
              <h4>{`טופל`}</h4>
            ) : (
              <h3>{`פנייה לטיפול`}</h3>
            )
          ) : (
            <h4>{`הפנייה טופלה`}</h4>
          )}
        </Header>
        <br />
        <InputsWrapper>
          <ContactInput
            placeholder=""
            value={ticket.fullName}
            isDisabled={true}
            setValue={setContent}
            isTextArea={false}
            topText={"שם"}
            bottomText={""}
          ></ContactInput>

          <ContactInput
            placeholder=""
            value={ticket.content}
            isDisabled={true}
            setValue={setContent}
            isTextArea={true}
            topText={"תוכן הפנייה"}
            bottomText={""}
          ></ContactInput>
          {isSupportUser ? (
            <ContactInput
              placeholder=""
              value={reply}
              setValue={setReply}
              isTextArea={true}
              topText={"תשובה"}
              bottomText={""}
            ></ContactInput>
          ) : (
            ticket.reply && (
              <ContactInput
                placeholder=""
                isDisabled={true}
                value={reply}
                setValue={setReply}
                isTextArea={true}
                topText={"תשובה"}
                bottomText={""}
              ></ContactInput>
            )
          )}
        </InputsWrapper>

        {isSupportUser && message && <Message>{message}</Message>}
        <Buttons>
          {isSupportUser && (
            <ContactButton
              onClick={async () => {
                const sent = await replyToTicket(ticket._id, reply);
                if (sent) {
                  setMessage("הפנייה נשלחה בהצלחה.");
                } else {
                  setMessage("קרתה תקלה בעת שליחת הפנייה");
                }
              }}
            >
              {"שלח"}
            </ContactButton>
          )}
          <ContactButton
            onClick={() => setViewTicket(false)}
          >{`חזור`}</ContactButton>
        </Buttons>
      </FormWrapper>
    </Wrapper>
  );
};

const Message = styled.div`
  font-weight: 600;
  margin-bottom: 1rem;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const InputsWrapper = styled.div`
  height: 60vh;
  width: inherit;
  margin: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: white;
  border-radius: 1rem;
  width: 90vw;
  margin-right: 5vw;
  max-height: 95vh;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    padding-top: 1rem;
  }
  p {
    align-self: center;
    text-align: center;
    /* font-size: 1.2rem; */
    font-weight: 600;
    width: 80%;
  }
`;
const Wrapper = styled.div`
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
`;
