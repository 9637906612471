import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Search} from "../../assets/Svgs/magnifier.svg";;
interface IProps {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    setSearchClick: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchInput = ({value, setValue, setSearchClick}: IProps) => {
    return <SearchWrapper>
        <input type="text" placeholder='חיפוש...' value={value} onChange={(e) => setValue(e.target.value)}/>
        <Search onClick={()=> {
            setSearchClick(true);
        }}/>
    </SearchWrapper>
}

const SearchWrapper = styled.div`
    display: flex;
    align-self: right;
    margin-top : 1rem;
    margin-bottom: 1rem;
    width: 90vw;
    svg{
        height: 0.9rem;
        aspect-ratio: 1;
        margin-top: 0.5rem;
        margin-right: 0.5rem;
    }
    input{
        border-radius: 1rem;
        padding-right: 0.8rem;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        outline: none;
        border: 1px solid #bbbbbb;
        font-size: 1rem;
        font-weight: 600;
    }
`;