import React from "react";

export const LShape = (id) => {
    const width = 40;
    const height = 50;
    const svgStyleObject = {
        position: "absolute",
        xmlns: "http://www.w3.org/2000/svg",
        width: width,
        height: height,
        marginTop: "-1rem",
        style: "vector-effect: non-scaling-stroke",
        stroke: "null",
        transform: "rotate(180deg)"
    }
    return (
        <svg style={svgStyleObject} className={id.id}>
            <g id="Layer_1">
                <title>Layer 1</title>
                <rect stroke="#000" id="svg_4" height="1" width={width} y="4.86319" x="5.46437" strokeWidth="0" fill="#a1a1a1" />
                <rect transform="rotate(90 4.87999 18.0416)" stroke="#000" id="svg_5" height="1" width={height} y="17.75466" x="-7.45858" strokeWidth="0" fill="#a1a1a1" />
                <ellipse stroke="#000" ry="5" rx="5" id="svg_1" cy="4.91394" cx="4.95697" strokeWidth="0" fill="#8fc8fa" />
            </g>
        </svg>
    )
}