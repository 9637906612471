import { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import { Document, Page } from "@react-pdf/renderer";
import styled from "styled-components";
import { DefaultContainer } from "../../containers/DefaultContainer";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useFile } from "../../hooks/useFile";

interface IProps {
  file: string;
  setFile: React.Dispatch<React.SetStateAction<string>>;
}

export const FileView = ({ file, setFile }: IProps) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { setTreeFile } = useFile();
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    // setPageNumber(numPages);
  }

  return (
    <Wrapper style={{ height: "100%", width: "96vw" }}>
      <FileViewButton
        onClick={() => {
          setFile("");
          setTreeFile({
            fileSrc: undefined,
            fileType: undefined,
            filename: undefined,
          });
        }}
      >
        חזור
      </FileViewButton>
      <StyleDocument
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        options={{ height: 300 }}
      >
        <Page height={800} width={350} pageNumber={pageNumber} />
      </StyleDocument>

      {numPages && (
        <PaginationWarper>
          <PaginationButton
            disabled={pageNumber === 1}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            <MdKeyboardArrowRight />
          </PaginationButton>

          <PagePos>
            עמוד {pageNumber} מתוך {numPages}
          </PagePos>
          <PaginationButton
            disabled={pageNumber === numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            <MdKeyboardArrowLeft />
          </PaginationButton>
        </PaginationWarper>
      )}
    </Wrapper>
  );
};

const FileViewButton = styled.button`
  margin: 1rem 0;
  padding: 0.5rem 2rem;
  max-width: 30vw;
  background-color: #3195ed;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 0.5rem;
  font-size: large;
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyleDocument = styled(Document)`
  display: flex;
  justify-content: center;

  .react-pdf__Page {
    display: flex;
    position: relative;
    max-width: 100vw;
    /* max-height: 100vh; */
    /* width: 100%; */
    /* height: 100%; */
    overflow: hidden;
    /* overflow: auto; */
  }
`;

const PagePos = styled.p`
  display: flex;
  justify-content: center;
`;
const PaginationWarper = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
const PaginationButton = styled.button`
  background: unset;
  display: flex;
  align-items: center;
  border: unset;
  border-radius: 100%;
  background: #8080804a;
  padding: 0.5rem;
  color: white;
  font-size: 20px;
  font-weight: 700;
`;
