import axios from "axios";
import React, { Dispatch, useState } from "react";
import { BASE_URL } from "../../../api/constants";
import downloadB from "../../../assets/Svgs/downloadB.svg";
import downloadY from "../../../assets/Svgs/downloadY.svg";
interface IProps {
  setText: Dispatch<React.SetStateAction<string>>;
  filename: string;
  email: string;
  shortName: string;
}

export const fileDownload = (email: String, filename: string) => {
  return axios
    .get(`${BASE_URL}/tree/downloadFile/${filename}`, {
      withCredentials: true,
    })
    .then((res) => {
      axios.post(
        `${BASE_URL}/fileLogs/sendFileLog`,
        { email, action: "Download", fileName: filename },
        {
          withCredentials: true,
        }
      );
      return res.data;
    })
    .catch((err) => {
      console.log(err);

      return false;
    });
};

export const FileDownload = ({
  setText,
  shortName,
  email,
  filename,
}: IProps) => {
  const [downloadLogo, setDownloadLogo] = useState(downloadB);

  return (
    <button
      onTouchStart={() => setDownloadLogo(downloadY)}
      onMouseDown={() => setDownloadLogo(downloadY)}
      onMouseUp={() => setDownloadLogo(downloadB)}
      onTouchEnd={() => setDownloadLogo(downloadB)}
      onClick={() => {
        setText("טוען...");
        fileDownload(email, filename).then((result) => {
          window.open(result);
          setText(shortName);
        });
      }}
    >
      <img className={"eye"} src={downloadLogo} alt={"צפייה"} />
    </button>
  );
};
