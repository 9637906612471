import styled from "styled-components";

export const VideoWrapper = styled.div`
  width: 100%;

  max-width: 700px;
  position: relative;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-self: center;
  overflow: hidden;
  border-radius: 10px;
  video {
    width: 100%;
  }
  @media (min-width: 768px) {
    height: 100%;
    max-height: 60vh;
    width: 100vw;
    video {
      height: 100%;
      max-height: 60vh;
      width: min-content;
    }
  }
`;
