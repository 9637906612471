import React, { Dispatch, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FilePreview } from "../../FileActions/Preview";
import { FileShare } from "../../FileActions/Share";
import { FileDelete } from "../../FileActions/Delete";
import { TreeNodeType } from "../../TreeNode";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../../store/userInfo";
import { permissions } from "../../../store/premissions";
import { FileDownload } from "../../FileActions/Download";
import usePermissions from "../../../hooks/usePermissions";
import { FileRename } from "../../FileReName/Index";

interface IProps {
  showModal: boolean;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  setRefresh: Dispatch<React.SetStateAction<boolean>>;
  setFile?: Dispatch<React.SetStateAction<string>>;
  setPopUpRename?: Dispatch<React.SetStateAction<boolean>>;
  setNameFile?: Dispatch<React.SetStateAction<string>>;
  node: TreeNodeType;
  fileType: string;
  refreshParent?: Function;
}

export const FilePopup = ({
  showModal,
  setShowModal,
  setFile,
  setRefresh,
  setNameFile,
  setPopUpRename,
  node,
  refreshParent,
  fileType,
}: IProps) => {
  const filename = node.displayName;
  const popup = useRef<HTMLDivElement>(null);
  const [shortName, setShortName] = useState("");
  const [text, setText] = useState(filename);
  const { isContentManagerUser, isAdminUser } = usePermissions(
    "isContentManagerUser"
  );
  useEffect(() => {
    const maxChars = 25;
    let trimmedName = filename.split(".").slice(0, -1).join(".");
    if (trimmedName) {
      if (trimmedName.length > maxChars) {
        trimmedName = trimmedName.substring(0, maxChars - 1) + "..";
      }
      setShortName(trimmedName);
      // setText(trimmedName);
    }
  }, []);

  const handleClick = (e: any) => {
    if (popup.current?.contains(e.target)) {
      // inside click
      return;
    }
    setShowModal(false);
    // outside click
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const user = useRecoilValue(userInfo);

  return (
    <>
      {showModal && (
        <BarStyle ref={popup}>
          <p>{text}</p>

          <Buttons>
            <FilePreview
              setText={setText}
              setFile={setFile}
              setRefresh={setRefresh}
              filename={node.name}
              email={user.user.userPrincipalName}
              shortName={shortName}
            />

            <FileDownload
              setText={setText}
              filename={node.name}
              email={user.user.userPrincipalName}
              shortName={shortName}
            />

            {isContentManagerUser && (
              <FileDelete
                setText={setText}
                shortName={shortName}
                node={node}
                refreshParent={refreshParent}
              />
            )}

            <FileShare
              setText={setText}
              filename={node.name}
              email={user.user.userPrincipalName}
              shortName={shortName}
            />
            {isContentManagerUser && (
              <FileRename
                filename={node.displayName}
                setPopUpRename={setPopUpRename}
                setNameFile={setNameFile}
              />
            )}
          </Buttons>
        </BarStyle>
      )}
    </>
  );
};

const Buttons = styled.div`
  width: auto;
  display: flex;
  justify-content: space-around;
`;

const BarStyle = styled.div`
  z-index: 2;
  width: 90vw;
  background: #161616;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  bottom: 1rem;
  /* justify-content: center; */
  /* align-items: center; */
  right: 5vw;

  p {
    margin: 0.5rem auto;
    /* padding-right: 1rem; */
    color: white;
    font-size: small;
  }

  button {
    background: none;
    border: none;
    padding-right: 1rem;
  }

  button:last-of-type {
    padding-right: 0.7rem;
  }

  img {
    padding: 0.5rem 0 0.5rem 0;
  }

  .star {
    width: 25px;
    height: 15px;
  }

  .eye {
    width: 25px;
    height: 15px;
  }

  .share {
    width: 25px;
    height: 15px;
  }
`;
