import React from "react";
import styled, { keyframes } from "styled-components";
import { LoadingPage } from "../LoadingPage";

const ring = keyframes`
0% {
   transform: rotate(0deg);
   box-shadow: 5px 5px 5px #e65c00;
   }
   50% {
   transform: rotate(180deg);
   box-shadow: 5px 5px 5px #18b201;
   }
100% {
   transform: rotate(360deg);
   box-shadow: 5px 5px 5px #0456c8;
    }
`;

const text = keyframes`
50% {
   color: white;
   }

`;
export const LoadingCircle = () => {
  return (
    <>
      <Loading>
        <div id="center">
          <div id="ring"></div>
          <span>כבר מעבירים אותך...</span>
        </div>
      </Loading>
    </>
  );
};

const Loading = styled.div`
  margin: 0;
  padding: 0;
  transform: scale(0.8);
  /* background: black; */
  #center {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
  }
  #ring {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    animation: ${ring} 2s linear infinite;
  }
  #ring:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  }
  span {
    color: #737373;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    line-height: 200px;
    animation: ${text} 3s ease-in-out infinite;
  }
`;
