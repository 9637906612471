import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Home } from "./components/Home/Home";
import { Files } from "./components/Files";
import { Login } from "./components/Login";
import { Contact } from "./components/Contact";
import { LogOut } from "./components/LogOut";
import { Search } from "./components/Search";
import { PrivateRoute } from "./atoms/PrivateRoute";
import { useRecoilState } from "recoil";
import { userInfo } from "./store/userInfo";
import axios from "axios";
import { BASE_URL } from "./api/constants";
import { useQuery } from "react-query";
import { Physiological } from "./components/Physiological";
import { Spinner } from "./components/TreeNode";
import { useEffect } from "react";
import AdminUpdates from "./components/AdminsUpdates";
import { DashBoard } from "./components/DashBoard";
import { LoadingCircle } from "./components/LoadingCircle";
import { LoadingPage } from "./components/LoadingPage";
import { SmartBasePage } from "./components/SmartBasePage";
// import { TreeTest } from "./components/TreeTest";
interface FullData {
  user?: Object;
  permission?: string;
}

function App() {
  const [user, setUser] = useRecoilState(userInfo);
  const getUserInfo = async () => {
    let fullData: FullData = {};
    const { data } = await axios.get(`${BASE_URL}/auth/isLogin`, {
      withCredentials: true,
    });
    if (data) {
      fullData.user = data;
      const res = await axios.get(`${BASE_URL}/user/getPermissions`, {
        withCredentials: true,
      });
      if (res.data?.success) {
        fullData.permission = res.data.permission;
      }
    }
    return fullData;
  };

  const { isLoading } = useQuery("user", getUserInfo, {
    onSuccess: (data) => {
      setUser({
        hasPermission: true,
        user: data.user,
        permissions: data?.permission ? [data.permission] : [],
      });
    },
    onError: () => setUser({ hasPermission: false }),
    retry: false,
  });

  // useEffect(() => {
  //   console.log("--- new version ---");
  // }, []);

  return (
    <Router>
      <div>
        {isLoading ? (
          <LoadingPage />
        ) : !user.hasPermission ? (
          <div>
            <Switch>
              <Route path="/">
                <Login />
              </Route>
            </Switch>
          </div>
        ) : (
          <div>
            <Switch>
              <PrivateRoute exact={true} path="/dashboard">
                <DashBoard />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/ammoLibrary">
                <Files rootId="616678782e1ab72ff167f13c" />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/womenFighters">
                <Files rootId="6242db5c2a942d5c960d136c" />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/armouredFightingVehicleLibrary">
                <Files rootId="6242d7082a942d5c960d136b" />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/frameworkOrdersLibrary">
                <Files rootId="6242dfef2a942d5c960d136d" />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/competencies">
                <Files rootId="6242e0342a942d5c960d136e" />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/techniques">
                <Files rootId="6242e0472a942d5c960d136f" />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/marom">
                <Files rootId="64199820e713d33fe2551b0b" />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/ironSwords">
                <Files rootId="652cd7228d93cd7bc58d0629" />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/search">
                <Search />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/adminUpdates">
                <AdminUpdates />
              </PrivateRoute>

              
              <Route path="/contact" exact={true} component={Contact} />
              <Route path="/logout" component={LogOut} />
              <PrivateRoute exact={false} path="/">
                <Home />
              </PrivateRoute>
            </Switch>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
