export const CARDS = [
  "הוראות מסגרת",
  "לוחמות",
  'רק"ם',
  "כשירויות",
  "טכניקות ותרגולות",
  'הוראות קחצ"ר',
  "מרום",
  "חרבות ברזל",
];
export const SMART_BASE_URL = "https://www.base.idf.il/";
export const ACADEMY_COURSES_URL =
  "https://academy.digital.idf.il/courses?categories=81";
