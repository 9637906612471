import { IoCloseCircle } from "react-icons/io5";
import { ImSearch } from "react-icons/im";
import styled from "styled-components";

import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { newSearch, searchWord } from "../../store/search";
import { useEffect } from "react";
import { ReactComponent as Magnifier } from "../../assets/Svgs/magnifier.svg";

export const SearchField = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useRecoilState(searchWord);
  const [isSearch, setIsSearch] = useRecoilState(newSearch);

  function handleChange(e: any) {
    setSearchTerm(e.target.value);
  }

  const handleSearch = () => {
    if (searchTerm) {
      history.push("/search");
      setIsSearch(true);
    }
  };

  useEffect(() => {
    setIsSearch(false);
  }, [searchTerm]);

  return (
    <SearchWarper>
      <SearchFieldWarper>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          {searchTerm && (
            <div style={{ color: "#D1D3D5" }} onClick={() => setSearchTerm("")}>
              <IoCloseCircle />
            </div>
          )}
          <input onChange={(e) => handleChange(e)} value={searchTerm} />

          <button type="submit" onClick={() => handleSearch()}>
            <Magnifier />
          </button>
        </form>
      </SearchFieldWarper>
      {/* <button
        style={{ cursor: "pointer" }}
        onClick={() => fetchNextPage()}
        disabled={!hasNextPage || isFetchingNextPage}>
        {isFetchingNextPage
          ? "Loading more..."
          : hasNextPage
          ? "Load More"
          : "Nothing more to load"}
      </button> */}
    </SearchWarper>
  );
};

const SearchFieldWarper = styled.div`
  width: 90%;
  margin-right: 0.5rem;
  form {
    display: flex;
    border-radius: 1rem;
    padding: 0.2rem 0.5rem;
    background: white;
    align-items: center;
    width: 100%;
    input {
      border: unset;
      width: 100%;
      outline: none;
      font-size: 18px;
    }
    svg {
      height: 1rem;
      width: 1rem;
    }
    button {
      background: none;
      border: unset;
      display: flex;
      align-items: center;
    }
  }
`;
const SearchWarper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;

  align-items: center;
`;
