const isLocal =
  typeof window != "undefined" && window.location.href.includes("localhost");
const isDev = !isLocal && window.location.href.includes("makahtzardev");
// const isLocal = true;
require("dotenv").config({ path: "./.env" });
//test
export const BASE_URL = isLocal
  ? "http://localhost:3001/api"
  : isDev
  ? "https://makahtzardevelopment.azurewebsites.net/api"
  : "https://makahtzarprod.azurewebsites.net/api";
