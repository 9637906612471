import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useFile } from "../../hooks/useFile";
import { VideoWrapper } from "./videoView.styles";
interface IProps {
  source?: string;
  type: string;
}

export const VideoView = ({ source, type }: IProps) => {
  const videoRef = useRef(null);
  const { setTreeFile } = useFile();
  return (
    <>
      <Wrapper style={{ height: "100%", width: "96vw" }}>
        <FileViewButton
          onClick={() => {
            setTreeFile({
              fileSrc: undefined,
              fileType: undefined,
              filename: undefined,
            });
          }}
        >
          חזור
        </FileViewButton>
        {source && (
          <VideoWrapper>
            <video ref={videoRef} controls>
              <source src={source} type={type} />
            </video>
          </VideoWrapper>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FileViewButton = styled.button`
  margin: 1rem 0;
  padding: 0.5rem 2rem;
  max-width: 30vw;
  background-color: #3195ed;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 0.5rem;
  font-size: large;
`;
