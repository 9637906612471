import { useRecoilValue } from "recoil";
import { userInfo } from "../store/userInfo";
import { permissions } from "../store/premissions";
import axios from "axios";
import { BASE_URL } from "../api/constants";
import { useEffect, useState } from "react";

let lastPage: String = "";
const usePermissions = (page: String) => {
  const user = useRecoilValue(userInfo);
  const isAdminUser = user && user?.permissions?.includes("admin");
  const isContentManagerUser =
    user &&
    (user?.permissions?.includes("content-manager") ||
      user?.permissions?.includes("admin"));
  const isSupportUser =
    user &&
    (user?.permissions?.includes("support-user") ||
      user?.permissions?.includes("admin"));

  return { isContentManagerUser, isAdminUser, isSupportUser, user };
};

export default usePermissions;
