import React, { useEffect } from "react";
import styled from "styled-components";

interface IProps {
  value: string;
  setValue: React.Dispatch<string>;
  text: string;
}

export const DateInput = ({ text, value, setValue }: IProps) => {
  useEffect(() => {}, [value]);
  return (
    <Wrapper>
      <h4>{text}</h4>
      <input
        type="date"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  h4 {
    margin: 0.2rem;
    margin-bottom: 0.5rem;
    transform: scale(0.9);
  }

  input[type="date"] {
    height: 5vh;
    width: 100%;
    outline: none;
    transform: scale(0.9);
    ::-webkit-calendar-picker-indicator {
      font-size: 1rem;
    }
    ::-webkit-clear-button {
      font-size: 5rem;
      height: 30px;
      position: relative;
    }
    ::-webkit-datetime-edit {
      padding: 1rem;
    }
  }
`;
