import React, { Dispatch, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { BASE_URL } from "../../../api/constants";
import { useHistory } from "react-router";

export interface IUpdate {
  _id: string;
  category: string;
  content: string;
  startDate: string;
  endDate: string;
  file?: string;
  createdAt: string;
  updatedAt: string;
}

interface IProps {
  update: IUpdate;
  setViewUpdate: Dispatch<React.SetStateAction<boolean>>;
  setUpdate: Dispatch<React.SetStateAction<IUpdate>>;
}

export const Update: React.FC<IProps> = ({
  update,
  setViewUpdate,
  setUpdate,
}) => {
  let days = -1;
  const startDate = new Date(update.startDate);
  let today = new Date();
  today.setUTCHours(0, 0, 0, 0);
  let endDate = new Date(update.endDate);

  endDate.setUTCHours(23, 59, 59);
  const isActive = startDate < new Date() && endDate > new Date();
  if (isActive) {
    days = daysBetweenDates(String(today), String(endDate));
  }
  return (
    <>
      <BackgroundDiv
        onClick={() => {
          setUpdate(update);
          setViewUpdate(true);
        }}
      >
        <Line open={isActive} />
        <CardContent>
          <div id="topCard">
            <div>{update.content}</div>
          </div>
          <div id="bottomCard">
            <Category>{update.category}</Category>
            {days === -1 ? (
              <Dates>
                <DateDiv>
                  <div>{startDate.getDate()}</div>
                  <div>{startDate.getMonth() + 1}.</div>
                  <div>{startDate.getFullYear().toString().substr(-2)}.</div>
                </DateDiv>
                -
                <DateDiv>
                  <div>{endDate.getDate()}</div>
                  <div>{endDate.getMonth() + 1}.</div>
                  <div>{endDate.getFullYear().toString().substr(-2)}.</div>
                </DateDiv>
              </Dates>
            ) : (
              <p>
                {days === 1 ? `מסתיים בסוף היום` : `מסתיים בעוד ${days} ימים`}
              </p>
            )}
          </div>
        </CardContent>
      </BackgroundDiv>
    </>
  );
};

const Dates = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const Category = styled.div`
  color: gray;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 93%;
  #topCard {
    display: flex;
    div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 95%;
    }
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 0.2rem;
    font-weight: 500;
    font-size: 1rem;
  }
  #status {
    font-weight: 500;
    font-size: 1.2rem;
  }
  #bottomCard {
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
      color: #4d4d4d;
    }
  }
`;

interface LineProps {
  open: boolean;
}

const Line = styled.div<LineProps>`
  height: 100%;
  min-width: 5px;
  background-color: ${(props) => (props.open ? "#46a32fc7" : "#ff6663")};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: 0.5rem;
`;
const DateDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 0 0.2rem;
  color: #4d4d4d;
`;

const BackgroundDiv = styled.div`
  background-color: #f2f2f2;
  width: 95%;
  border-radius: 5px;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  height: 4rem;
  box-shadow: 0px 2px 4px rgba(93, 98, 109, 0.4);
`;

const daysBetweenDates = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  console.log(start, end);

  const diffTime = Math.abs(end.getTime() - start.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};
