import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
// import logo from "../../assets/logo.png";
// import logo from "../../assets/Svgs/logo.svg";
import logo from "../../assets/homepage/makahtzar_logo.png";
// import logo from "../../../public/shieldIcon.png";

export const RightSide = () => {
  const history = useHistory();

  return (
    <RightSideWrapper
      onClick={() => {
        history.push("/");
      }}>
      <LogoStyle src={logo} />
      <SafetyHeader>{"K.click"}</SafetyHeader>
    </RightSideWrapper>
  );
};

const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0;
  cursor: pointer;
  max-width: 50%;
`;

const LogoStyle = styled.img`
  width: 3rem;
  aspect-ratio: 1.2;
  margin: 0;
  margin-top: 0.2rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`;

const SafetyHeader = styled.div`
  /* padding-right: 1rem; */
  white-space: nowrap;
  font-size: 20px;
  font-weight: bolder;
  color: white;
  margin-right: -0.2rem;
  margin-top: -0.2rem;
`;
