import { useEffect, useState } from "react";
import styled from "styled-components";
import { BASE_URL } from "../../api/constants";
import bg from "../../assets/login/bg.png";
import { ContactButton } from "./ContactButton"; //בשביל הכפתור
import { ContactInput } from "./ContactInput";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import axios from "axios";
import { Ticket } from "./Ticket";
import { Dispatch } from "react";
import { ITicket } from ".";
import { ViewTicket } from "./ViewTicket";
import { useHistory } from "react-router";
interface IProps {
  setNewTicket: Dispatch<React.SetStateAction<boolean>>;
}

export const ContactMyTickets = ({ setNewTicket }: IProps) => {
  const history = useHistory();
  const user = useRecoilValue(userInfo); //מידע של יוזר
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [viewTicket, setViewTicket] = useState(false);
  const [ticket, setTicket] = useState<ITicket>({} as ITicket);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!viewTicket) {
      setIsLoading(true);
      axios
        .post(
          `${BASE_URL}/contactTickets/getMyTickets`,
          {
            email: user.user.userPrincipalName,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res?.data?.success) {
            setTickets(res.data.tickets);
            setIsLoading(false);
          }
        });
    }
  }, [viewTicket]);
  return (
    <>
      {viewTicket ? (
        <ViewTicket
          isSupportUser={false}
          setViewTicket={setViewTicket}
          ticket={ticket}
        />
      ) : (
        <Wrapper>
          <br />
          <FormWrapper>
            <Header>
              <h3>{`הפניות שלי`}</h3>
            </Header>
            <ContactButton
              onClick={() => setNewTicket(true)}
            >{`+ יצירת פנייה חדשה`}</ContactButton>
            <p className="back" onClick={() => history.push("/")}>
              {`חזור`}
            </p>
            <br />
            {isLoading ? (
              <p>{`טוען..`}</p>
            ) : (
              <TicketsWrapper>
                {tickets.length ? (
                  tickets.map((ticket) => (
                    <Ticket
                      key={ticket._id}
                      isSupportUser={false}
                      ticket={ticket}
                      setViewTicket={setViewTicket}
                      setTicket={setTicket}
                    />
                  ))
                ) : (
                  <p>{`אין פניות פתוחות`}</p>
                )}
              </TicketsWrapper>
            )}
          </FormWrapper>
        </Wrapper>
      )}
    </>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;

  h3 {
    padding-top: 1rem;
    margin: 0;
  }
  p {
    align-self: center;
    text-align: center;
    font-weight: 600;
    width: 80%;
  }
`;

const TicketsWrapper = styled.div`
  max-height: 50vh;
  width: inherit;
  margin: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: white;
  border-radius: 0.5rem;
  width: 90vw;
  margin-right: 5vw;
  max-height: 95vh;
  padding-bottom: 1rem;
  .back {
    position: absolute;
    top: 1.3rem;
    right: 2rem;
    border-bottom: 1px solid #000;
    /* padding-bottom: 1px; */
  }
`;

const Wrapper = styled.div`
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
`;
