import styled from "styled-components";

export const Button = styled.button`
  margin: 1rem 0;
  padding: 0.5rem 2rem;
  background-color: #3195ed;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 0.5rem;
  font-size: large;
  :disabled {
    background-color: #c0c0c0;
  }
`;
