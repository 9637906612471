import styled from "styled-components";
import { Header } from "../components/Header/Header";
import blueBg from "../assets/blue-background.jpg";

interface IProps {
  children: React.ReactNode;
  background?: boolean;
  search?: boolean;
  plus?: boolean;
  overflow?: string;
  height?: number;
}

export const DefaultContainer = ({
  children,
  background,
  search,
  plus,
  overflow,
}: IProps) => {
  return (
    <AppWrapper background={background} overflow={overflow}>
      <Header plus={plus} />
      {children}
    </AppWrapper>
  );
};

const AppWrapper = styled.div`
  background: ${(props: IProps) => props.background === true && `#d1d1d1`};
  overflow: ${(props) => props.overflow};
  background-size: cover;
  position: relative;
  /* height: unset; */
  height: ${(props) => (props?.height ? `${props.height}%` : "unset")};
  min-height: 100vh;
  width: 100vw;
`;
