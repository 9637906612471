import axios from "axios";
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { BASE_URL } from "../../api/constants";
import { DefaultContainer } from "../../containers/DefaultContainer";
import { CheckBox } from "../CheckBox";
import { DateInput } from "../DateInput";
import { List } from "../List";
import { Search } from "../Search";
import { BsSearch } from "react-icons/bs";
import { CARDS } from "../../constants";
import { ReactComponent as entrance } from "../../assets/Svgs/entrance.svg";
import { ReactComponent as userProfile } from "../../assets/Svgs/userProfile.svg";
import { ReactComponent as group } from "../../assets/Svgs/group.svg";
import { DashBoardCard } from "../DashboardCard";
import { ReactComponent as contentManager } from "../../assets/Svgs/contentManager.svg";
import { ReactComponent as safteySchedule } from "../../assets/Svgs/safteySchedule.svg";
import { ReactComponent as ammoTin } from "../../assets/Svgs/ammoTin.svg";
import { ReactComponent as womanStretch } from "../../assets/Svgs/womanStretch.svg";
import { ReactComponent as tank } from "../../assets/Svgs/tank.svg";
import { ReactComponent as fitness } from "../../assets/Svgs/fitness.svg";
import { ReactComponent as marom } from "../../assets/Svgs/marom.svg";
import { ReactComponent as Swords } from "../../assets/Svgs/ironSwords.svg";

import { PopularFile } from "../PopularFile";
import { SearchInput } from "../SearchInput";
import { Card } from "../../atoms/Card";

const spin = keyframes`
  0% {
     transform: rotate(0deg);
     }
  100% {
     transform: rotate(360deg);
      }
`;

const logos: any = {
  [CARDS[0]]: contentManager,
  [CARDS[1]]: womanStretch,
  [CARDS[2]]: tank,
  [CARDS[3]]: fitness,
  [CARDS[4]]: safteySchedule,
  [CARDS[5]]: ammoTin,
  [CARDS[6]]: marom,
  [CARDS[7]]: Swords,
};

const colors: any = {
  [CARDS[0]]: "#0095CD",
  [CARDS[1]]: "#FF6DE5",
  [CARDS[2]]: "#23CD52",
  [CARDS[3]]: "#936EFF",
  [CARDS[4]]: "#FFA368",
  [CARDS[5]]: "#4A74FF",
  [CARDS[7]]: "#c70909",
};

export const DashBoard = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [differentUsers, setDifferentUsers] = useState(undefined);
  const [countUser, setCountUser] = useState(undefined);
  const [today, setToday] = useState<boolean>(true);
  const [usersAmount, setUsersAmount] = useState(undefined);
  const [newUsersAmount, setNewUsersAmount] = useState(undefined);
  const [cardsInfo, setCardsInfo] = useState<any>(undefined);
  const [mostViewedFiles, setMostViewedFiles] = useState([]);
  const [showFileReportIndex, setShowFileReportIndex] = useState(-1);
  const [searchValue, setSearchValue] = useState("");
  const [searchClick, setSearchClick] = useState(false);
  const [searchedFiles, setSearchedFiles] = useState<any>(undefined);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  useEffect(() => {
    if (searchClick) {
      setIsSearchLoading(true);
      axios
        .post(
          `${BASE_URL}/dashboard/searchFiles`,
          {
            searchValue: searchValue,
          },
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          setSearchedFiles(result.data.result);
          setIsSearchLoading(false);
        });
    }
    setSearchClick(false);
  }, [searchClick]);

  useEffect(() => {
    setIsSearchLoading(true);
    axios
      .post(
        `${BASE_URL}/dashboard/searchFiles`,
        {
          searchValue: "",
        },
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        setSearchedFiles(result.data.result);
        setIsSearchLoading(false);
      });

    setUsersAmount(undefined);
    axios
      .post(
        `${BASE_URL}/dashboard/countUsers`,
        { startDate: new Date(2021, 10, 1).getTime(), endDate: Date.now() },
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if (result.data.success) {
          setUsersAmount(result.data.amount);
        }
      });
  }, []);

  useEffect(() => {
    let startDateFinal;
    let endDateFinal;
    setDifferentUsers(undefined);
    setCardsInfo(undefined);
    setCountUser(undefined);

    setNewUsersAmount(undefined);
    let search = false;
    if (today) {
      startDateFinal = new Date().setUTCHours(0, 0, 0, 0);
      endDateFinal = new Date().setUTCHours(23, 59, 59, 59);
      setEndDate("");
      setStartDate("");
      search = true;
    } else if (startDate && endDate) {
      startDateFinal = startDate;
      endDateFinal = endDate;
      search = true;
    }

    if (search) {
      axios
        .post(
          `${BASE_URL}/dashboard/getUsers`,
          { startDate: startDateFinal, endDate: endDateFinal },
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          if (result.data.success) {
            setCountUser(result.data.amount);
          }
        });

      axios
        .post(
          `${BASE_URL}/dashboard/countUsers`,
          { startDate: startDateFinal, endDate: endDateFinal },
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          if (result.data.success) {
            setNewUsersAmount(result.data.amount);
          }
        });

      axios
        .post(
          `${BASE_URL}/dashboard/getDifferentUsers`,
          {
            startDate: startDateFinal,
            endDate: endDateFinal,
          },
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          if (result.data.success) {
            setDifferentUsers(result.data.result);
          }
        });

      axios
        .post(
          `${BASE_URL}/areaLogs/getAllAreas`,
          {
            startDate: startDateFinal,
            endDate: endDateFinal,
            areaNames: CARDS,
          },
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          console.log(result.data.success);
          if (result.data.success) {
            setCardsInfo(result.data.result);
          }
        });

      axios
        .post(
          `${BASE_URL}/dashboard/mostViewedFiles`,
          {
            startDate: startDateFinal,
            endDate: endDateFinal,
          },
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          setMostViewedFiles(result.data.result);
        });
    }
  }, [startDate, endDate, today]);

  return (
    <DefaultContainer>
      <Title>
        <div id="title">
          <div>משתמשים באפליקציה</div>
        </div>
        <div id="countUser">
          {usersAmount === undefined ? (
            <Spinner />
          ) : usersAmount === 0 ? (
            "אין תוצאות"
          ) : (
            usersAmount
          )}
        </div>
      </Title>

      <DivCheckBox>
        <CheckBox
          marginRight={1}
          text="מידע עדכני של היום"
          clicked={today}
          setClicked={setToday}
        />
      </DivCheckBox>

      <DivDate today={today}>
        <div>
          <DateInput
            text={"תאריך התחלה"}
            value={startDate}
            setValue={setStartDate}
          />
        </div>
        <div>
          <DateInput
            text={"תאריך סיום"}
            value={endDate}
            setValue={setEndDate}
          />
        </div>
      </DivDate>
      <br></br>
      <BorderSilver today={today}></BorderSilver>
      <Content>
        <DivCard>
          <DashBoardCard
            text={"משתמשים שונים"}
            Icon={group}
            number={differentUsers}
            color={"#2eb8b8"}
          />
          <DashBoardCard
            text={"משתמשים חדשים"}
            Icon={userProfile}
            number={newUsersAmount}
            color={"#0099e6"}
          />
        </DivCard>
        <DivCard id="rowCardTwo">
          <DashBoardCard
            text={"כמות כניסות"}
            Icon={entrance}
            number={countUser}
            color={"#d81c1c"}
          />
        </DivCard>

        {/* <DivDataDisplay>
          <div id="title">כמות משתמשים כוללת</div>
          <div>
            {usersAmount === undefined
              ? "טוען..."
              : usersAmount === 0
              ? "אין תוצאות"
              : usersAmount}
          </div>
        </DivDataDisplay> */}
        {/* <DivDataDisplay>
          <div id="title">כמות משתמשים שונים שנכנסו לאתר</div>
          <div>
            {differentUsers === undefined
              ? "טוען..."
              : differentUsers === 0
              ? "אין תוצאות"
              : differentUsers}
          </div>
        </DivDataDisplay> */}
        {/* <DivDataDisplay>
          <div id="title">כמות משתמשים חדשים</div>
          <div>
            {newUsersAmount === undefined
              ? "טוען..."
              : newUsersAmount === 0
              ? "אין תוצאות"
              : newUsersAmount}
          </div>
        </DivDataDisplay> */}

        <DivList>
          <div id="titleList">כמות כניסות לפי אזורים</div>
          {cardsInfo === undefined ? (
            "טוען..."
          ) : Object.keys(cardsInfo).length === 0 ? (
            "אין תוצאות"
          ) : (
            <div id="cardList">
              {Object.keys(cardsInfo).map((card) => {
                return (
                  <DashBoardCard
                    text={card}
                    number={cardsInfo[card]}
                    Icon={logos[card]}
                    color={colors[card]}
                  />
                );
              })}
            </div>
          )}
        </DivList>
        {/* <SearchFile>
          <div id="titleSearch">חיפוש הוראה</div>
          <DivSearch>
            <input
              placeholder="הזן את שם ההוראה..."
              id="search"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            ></input>
            <div id="iconSearch" onClick={() => console.log(search)}>
              חיפוש
            </div>
          </DivSearch>
          <div>
            <ul>
              <List text={"עידו"} number={10} />
            </ul>
          </div>
        </SearchFile> */}
        <DivList>
          <div id="titleList">עשרת הקבצים הכי פופולריים</div>

          {mostViewedFiles?.length !== 0
            ? mostViewedFiles?.map((file: any, index) => {
                let name = file.file[0].name;
                name = name.split(".").slice(0, -1).join(".");
                let startDateFinal = new Date();
                let endDateFinal = new Date();
                if (today) {
                  startDateFinal = new Date(
                    startDateFinal.setUTCHours(0, 0, 0, 0)
                  );
                  endDateFinal = new Date(
                    new Date().setUTCHours(23, 59, 59, 59)
                  );
                } else if (startDate && endDate) {
                  startDateFinal = new Date(startDate);
                  endDateFinal = new Date(endDate);
                }
                return (
                  <PopularFile
                    startDate={startDateFinal}
                    endDate={endDateFinal}
                    fileId={file.file[0]._id}
                    text={name}
                    number={file.count}
                  />
                );
              })
            : "טוען..."}
        </DivList>
        <DivList>
          <div id="titleList">חיפוש קבצים</div>
          <SearchContainer>
            <SearchInput
              value={searchValue}
              setValue={setSearchValue}
              setSearchClick={setSearchClick}
            />
            <div className="fileWrapper">
              {isSearchLoading
                ? "טוען..."
                : searchedFiles?.length !== 0
                ? searchedFiles?.map((file: any) => {
                    let name = file.file[0].name;
                    name = name.split(".").slice(0, -1).join(".");

                    return (
                      <PopularFile
                        startDate={new Date(12, 12, 2020)}
                        endDate={new Date()}
                        fileId={file.file[0]._id}
                        text={name}
                        number={file.count}
                        colorOne="#ec7d3d"
                        colorTwo="#fddc32"
                      />
                    );
                  })
                : "אין תוצאות"}
            </div>
          </SearchContainer>
        </DivList>
      </Content>
    </DefaultContainer>
  );
};

const SearchContainer = styled.div`
  margin-right: -1rem;
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .fileWrapper {
    overflow: scroll;
  }
`;

const DivCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  padding-top: 0.5rem;
  &&#rowCardTwo {
    margin-top: 0.5rem;
  }
`;

// const DivSearch = styled.div`
//   margin-top: 0.5rem;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   width: 80vw;
//   #search {
//     width: 60vw;
//     /* border: none; */
//     border-top-right-radius: 5px;
//     border-bottom-right-radius: 5px;
//     border-bottom: 2px solid black;
//     border-right: 2px solid black;
//     border-left: none;
//     border-top: 2px solid black;
//     background: none;
//     outline: none;
//     font-size: 1rem;
//     padding-right: 0.5rem;
//     height: 1.5rem;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
//   #iconSearch {
//     font-size: 1.2rem;
//     background-color: #cccccc;
//     border-top-left-radius: 5px;
//     border-bottom-left-radius: 5px;
//     border-bottom: 2px solid black;
//     border-right: none;
//     border-left: 2px solid black;
//     border-top: 2px solid black;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 1.6rem;
//     font-weight: 700;
//   }
// `;

// const SearchFile = styled.div`
//   display: flex;
//   flex-direction: column;
//   /* justify-content: space-between; */
//   margin-top: 1rem;
//   min-height: 10vh;
//   #titleSearch {
//     font-weight: bold;
//     border-bottom: 1px solid silver;
//     width: 25vw;
//   }
// `;

const DivList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  #titleList {
    font-weight: bold;
    border-bottom: 1px solid silver;
    display: flex;
    width: fit-content;
    margin-top: 1.2rem;
    /* border-bottom:1px dotted silver; */
  }
  #cardList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    padding-top: 0.5rem;
    gap: 1rem;
  }
`;

const Content = styled.div`
  padding-right: 1rem;
  background-color: #fafcfd;
  padding-bottom: 2rem;
  /* min-height: 100vh; */
`;
const DivDataDisplay = styled.div`
  padding-top: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75vw;
  #title {
    font-weight: bold;
    /* border-bottom:1px dotted silver; */
  }
`;

const BorderSilver = styled.div<{ today: boolean }>`
  margin-top: ${(props) => (props.today ? "1rem" : "6.5rem")};
  /* border-bottom: 1.5px dashed black; */
  transition: all 0.35s ease-out;
`;

const DivCheckBox = styled.div`
  padding-right: 1rem;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10rem;
  width: 75%;
  height: 8vh;
  margin: auto;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  /* border: 2px solid silver; */
  border: 2px solid #f6f8f7;
  box-shadow: 2px 5px 15px 5px #838683;

  #title {
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    font-weight: bold;
  }
  #countUser {
    padding-left: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
  }
`;

const DivDate = styled.div<{ today: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 98%;
  opacity: ${(props) => (props.today ? "0" : "1")};
  transition: all 0.4s ease-out;
  /* transform: scale(0.9); */
  position: absolute;
  right: ${(props) => (props.today ? "-10rem" : "0")};
`;

const Spinner = styled.div`
  /* position: relative;
  bottom: 2.4rem;
  right: 1.25rem; */
  /* top: 0.5rem; */
  border: 0.3rem solid #f3f3f3; /* Light grey */
  border-top: 0.3rem solid #8fc8fa; /* Blue */
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  /* z-index: -1; */
  animation: ${spin} 2s linear infinite;
  /* height: 8rem; */
`;
