import { useState } from "react";
import styled from "styled-components";
import { BASE_URL } from "../../api/constants";
import bg from "../../assets/login/bg.png";
import usePermissions from "../../hooks/usePermissions";
import { ContactButton } from "./ContactButton";
import { ContactInput } from "./ContactInput";
import { ContactUser } from "./ContactUser";
import { ContactMyTickets } from "./ContactMyTickets";
import { ContactSupportTickets } from "./ContactSupportTickets";

export interface ITicket {
  _id: string;
  user: string;
  type: string;
  fullName: string;
  reply?: string;
  content: string;
  status: string;
  createdAt: Date;
}

export const Contact = () => {
  const { isSupportUser } = usePermissions("contact");

  return <>{isSupportUser ? <ContactSupportTickets /> : <ContactUser />}</>;
};
